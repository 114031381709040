import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import loadable from '@loadable/component'
import './assets/styles/_index.scss'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { sliderSettings3 } from "../SliderSettings/SliderSettings"
import { StaticImage } from "gatsby-plugin-image"

import GoogleReviewImg from "../../images/google_reviews_img.svg";
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule");
const Slider = loadable(() => import("react-slick"))

const SafeHands = (props) => {

    const data = useStaticQuery(graphql`
  query {
    allGoogleReviews {
        totalCount
        edges {
            node {
                id
                reviewer {
                displayName
                }
                comment
                starRating
            }
        }
    }
    googleReviews(starRating: {eq: "FIVE"}) {
      comment
      starRating
      reviewer {
      displayName
      }
    }
  }
`);
    const reviewsList = data.googleReviews;
    const reviewCount = data?.allGoogleReviews?.totalCount
    const allReviewsList = data?.allGoogleReviews?.edges

    const wordToNumber = { 'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5 };

    var testimonial_count = reviewCount;
    var rating_count = 0;
    allReviewsList && allReviewsList.length > 0 && allReviewsList.map(({node}, ind) => {
        rating_count += parseInt(wordToNumber[node.starRating]);
    })
    var rating_avg = rating_count / testimonial_count;

    return (
        <section className="safe-hands-wrapper section-p">
            <Container>
                <Row className="d-flex justify-content-center">
                    {props.ptag !== "office-details" ?
                        <Col xl={7} className="text-center">
                            {props.content && <ContentModule Content={props.content.data.content} />}
                        </Col> :
                        <Col xl={7} className="text-center">
                            {props.review_slider_intro && <ContentModule Content={props.review_slider_intro.data.review_slider_intro} />}
                        </Col>}
                </Row>
                <div className="safe-hands-content-wrapper-main">
                    <Row className="d-xl-flex align-items-center">
                        <Col xl={5} className="order-xl-1 order-2">
                        <Slider {...sliderSettings3}>
                            {allReviewsList && allReviewsList.slice(0,10).map(({node}, index) => (
                            node.starRating === "FIVE" && <div className="safe-hands-content-wrapper">
                                <div className="safe-hands-review-text">“{node?.comment &&
                                        node?.comment.length > 300
                                        ? node?.comment.substring(0, 300) + "... "
                                        : node?.comment}”</div>
                                <div className="safe-hands-review-name">{node.reviewer?.displayName}</div>
                                {/* <div className="safe-hands-review-position">Landlord</div> */}
                                <div className="border-line"></div>
                                <div className="safe-hands-google-wrapper d-md-flex align-items-center justify-content-center text-center">
                                    <img src={GoogleReviewImg} className="img-fluid" alt="Google Review Logo" />
                                    <div className="safe-hands-google-text"><span>{rating_avg ? parseFloat(rating_avg).toFixed(1) : 0}/5</span> from {reviewCount} Reviews </div>
                                </div>
                            </div>

                            ))}
                            </Slider>
                        </Col>
                        <Col xl={1} className="order-xl-2"></Col>
                        <Col xl={6} className="order-xl-3 order-1">
                            <div className="safe-hands-img img-wrapper">
                                <StaticImage src="../../images/safe_hands_img.png" layout="fullWidth" placeholder="blurred" formats={["webp"]} alt="banner" quality="90" className="img-fluid" />
                                {/* <img src={SafeHandsImg} className="img-fluid" alt="Madisonfox" /> */}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default SafeHands