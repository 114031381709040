import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import loadable from "@loadable/component"
import "./assets/styles/_index.scss"

const CountUp = loadable(() => import("react-countup"))
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const StatsModule = props => {
  function isFloat(n) {
    return Number(n) === n && n % 1 !== 0
  }

  return (
    <section className="stats-module-wrapper section-p">
      <Container>
        <Row>
          {props.add_stats.map((item, index) => (
            <Col xl={4}>
              <div className="stats-module-wrapper-list">
                <div className="stats-module-count">
                  {item?.stats_count && (
                    <CountUp
                      prefix={item?.stats_preffix || ""}
                      suffix={item?.stats_suffix || ""}
                      end={item?.stats_count?.replaceAll(",", "")}
                      decimals={isFloat(Number(item?.stats_count)) ? 1 : 0}
                      separator=","
                      enableScrollSpy
                      scrollSpyOnce
                    />
                  )}
                </div>
                {item.stats_content && (
                  <ContentModule
                    Content={item.stats_content.data.stats_content}
                  />
                )}
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default StatsModule
