import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import './assets/styles/_index.scss'
import GGFXImage from "../GGFX/GGFXImage"

const { CTALink } = require("@starberry/gatsby-theme-utils/Modules/CTALinkModule")
const { ImageModule } = require("@starberry/gatsby-theme-utils/Modules/ImageModule")
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule");

const WhyChoose = (props) => {
    var imagename = "page.about_section_banner_image.about_tile_image";

    let processedImages = JSON.stringify({});
    if (props?.imagetransforms?.about_section_banner_image_Transforms) {
        processedImages = props?.imagetransforms?.about_section_banner_image_Transforms;
    }
    return (
        <>
        <section className="why-choose-wrapper section-p">
            <Container>
                <Row className="d-xl-flex align-items-center">
                    <Col xl={5} className="order-xl-1 order-2">
                        <div className="why-choose-content-wrapper">
                            {props.heading && <div className="why-choose-title-sm">{props.heading}</div>}
                            {props.description && <div className={`why-choose-title ${props.add_here && props.add_here.length > 0 ? '' : 'about-content'}`}>
                                <ContentModule Content={props.description?.data.description} />
                            </div>}
                            {(props?.cta_title && props?.cta_link) &&
                                <CTALink class="button button-primary" link={props.cta_link} >{props.cta_title}</CTALink>
                            }

                            {props.add_here && <ul className="list-unstyled">
                                {props.add_here.map((item, index) => (
                                    <li>
                                        <div className="why-choose-sub-content-wrapper d-flex">
                                            <div className="why-choose-icon-wrapper">
                                                <img src={item.icon_image?.url} alt={item.icon_image?.alternativeText} />
                                            </div>
                                            <div className="why-choose-icon-content-wrapper">
                                                <ContentModule Content={item.content?.data.content} />
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>}
                        </div>
                    </Col>
                    <Col xl={1} className="order-xl-2"></Col>
                    <Col xl={6} className="order-xl-3 order-1">
                        <div className="why-choose-img img-wrapper">
                            <GGFXImage
                                ImageSrc={props?.image}
                                altText={props?.image?.alternativeText}
                                imagetransforms={props.ggfx_results}
                                renderer="srcSet"
                                imagename={imagename}
                                strapiID={props?.id}
                                className="img-fluid"
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <Container>
        {props.showBottomLine && <div className="border-line"></div>}
        </Container>
        </>
    )
}

export default WhyChoose