import React from "react"
import loadable from "@loadable/component"
import { Container, Row, Col } from "react-bootstrap"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { sliderSettings2 } from "../SliderSettings/SliderSettings"
import './assets/styles/_index.scss'
import GGFXImage from "../GGFX/GGFXImage"

const Slider = loadable(() => import("react-slick"))

const { ImageModule } = require("@starberry/gatsby-theme-utils/Modules/ImageModule")
const { CTALink } = require("@starberry/gatsby-theme-utils/Modules/CTALinkModule")
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule");

const WhatDoYouNeed = (props) => {
    var imagename = "page.service_section_image.service_image";
    if (props.ptag === "office-details") {
        imagename = "office-detail.service_section_image.service_image";
    }

    let processedImages = JSON.stringify({});
    if (props?.imagetransforms?.service_section_image_Transforms) {
        processedImages = props?.imagetransforms?.service_section_image_Transforms;
    }
    return (
        <>
            {props.showDividerLine && <div className='divider-line'></div>}
            <section className={`what-do-you-need-wrapper ${props.background === "light_color" ? "grey-bg" : props.background === "transparent_color" ? "grey-bg white-bg" : ""}`}>
                <Container>
                    {props.services_intro && <Row className="d-flex justify-content-center">
                        <Col xl={7} className="text-center">
                            <ContentModule Content={props.services_intro?.data.services_intro} />
                        </Col>
                    </Row>}
                    <Row>
                        <Slider className="what-do-you-need-slider" {...sliderSettings2}>
                            {
                                props.add && props.add.map((property, i) => {
                                    return (
                                        <div className="what-do-you-need-slide" key={i}>
                                            {property?.image && <div className="what-do-you-need-img-zoom img-wrapper">
                                                <CTALink link={property?.cta_link} title="" target_window={property?.cta_link?.target_window}>
                                                    <GGFXImage
                                                        ImageSrc={property?.image}
                                                        altText={property?.image?.alternativeText}
                                                        imagetransforms={props.ggfx_results}
                                                        renderer="srcSet"
                                                        imagename={imagename}
                                                        strapiID={props?.id}
                                                        className="img-fluid banner-img"
                                                    />
                                                </CTALink>
                                            </div>}
                                            <div className="what-do-you-need-content-wrapper">
                                                {property.cta_link ? <CTALink link={property.cta_link} title={property.title} target_window={property.cta_link.target_window} class="what-do-you-title link" /> :
                                                <div className="what-do-you-title">{property.title}</div> }
                                                {property.content && <ContentModule Content={property.content?.data.content} />}
                                                {property.cta_link && <div className="what-do-you-need-link">
                                                    <CTALink link={property.cta_link} title={property.cta_title} target_window={property.cta_link.target_window} />
                                                </div>}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default WhatDoYouNeed