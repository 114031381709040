import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import { graphql } from 'gatsby'
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/Banner/Banner"
import GoogleReviews from "../components/GoogleReviews/GoogleReviews"
import WhatDoYouNeed from "../components/WhatDoYouNeed/WhatDoYouNeed"
import WhyChoose from "../components/WhyChoose/WhyChoose"
import StatsModule from "../components/StatsModule/StatsModule"
import SafeHands from "../components/SafeHands/SafeHands"
import SellLetHome from "../components/SellLetHome/SellLetHome"

const RecentProperties = loadable(() => import("../components/RecentProperties/FeaturedProperties"))

const IndexPage = ({ data }) => {
  const PageData = data?.strapiPage

  const [renderComponent, setRenderComponent] = useState(false);
  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })

  }, [])

  return (
    <Layout popularSearch={PageData?.select_popular_search?.title}>

      <Banner
        {...PageData.banner}
        title={PageData.banner?.banner_title}
        id={PageData.strapi_id}
        imagetransforms={PageData.imagetransforms}
        btnPrimaryIconClass="icon-banner-valuation"
        btnPrimaryLightIconClass="icon-banner-search"
        ggfx_results={PageData?.ggfx_results}
        page="home"
      />

      {PageData?.add_page_modules?.length > 0 && PageData.add_page_modules?.map((module, index) => {
        return (
          <>
            {module.strapi_component === "page-modules.global-module" && module.select_module === "google_review_badge" && <GoogleReviews />}
            {module.strapi_component === "page-modules.services-tiles" && <WhatDoYouNeed id={PageData.strapi_id} {...module} ggfx_results={PageData?.ggfx_results} />}
            {module.strapi_component === "page-modules.image-and-content" && <WhyChoose id={PageData.strapi_id} {...module} ggfx_results={PageData?.ggfx_results} />}
            {module.strapi_component === "page-modules.statistics" && <><Container>
              <Row>
                <Col>
                  <div className="border-line"></div>
                </Col>
              </Row>
            </Container>

              <StatsModule {...module} />
            </>
            }
            {module.strapi_component === "page-modules.global-module" && module.select_module === "featured_properties" && renderComponent && <RecentProperties {...module} /> }
            {module.strapi_component === "page-modules.global-module" && module.select_module === "google_review_slider" && <SafeHands {...module} /> }
            {module.strapi_component === "page-modules.plain-content" && module.layout === "bg_color_center_align" && <SellLetHome {...module} /> }
          </>
        )
      }
      )}
    </Layout>
  )
}


export const Head = ({ data }, props) => {
  const PageData = data?.strapiPage
  const siteData = data?.strapiSiteConfig

  var schemaSameAs = ''
  if (siteData?.facebook_link.length > 1) {
    schemaSameAs = siteData?.facebook_link + ','
  }
  if (siteData?.twitter_link.length > 1) {
    schemaSameAs += siteData?.twitter_link + ','
  }
  if (siteData?.instagram_link.length > 1) {
    schemaSameAs += siteData?.instagram_link + ','
  }
  if (siteData?.linkedin_link.length > 1) {
    schemaSameAs += siteData?.linkedin_link + ','
  }

  var ldJson = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": process.env.GATSBY_SITE_URL,
    "name": process.env.GATSBY_SITE_NAME,
    "alternateName": process.env.GATSBY_SITE_NAME,
    "logo": process.env.GATSBY_SITE_URL + `/images/logo.png`,
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": siteData?.mobile_device_phone,
      "areaServed": process.env.GATSBY_DEFAULT_AREA,
      "contactType": "Sales"
    },
    "sameAs": schemaSameAs.replace(/,\s*$/, "").split(',')
  };

  return (
    <Seo title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
      />
    </Seo>
  )
}


export default IndexPage



export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        add_page_modules {
              ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
                  ...GlobalModuleFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_AND_CONTENT {
                  ...ImageAndContentFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
                  ...PlainContentFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_SERVICES_TILES {
                  ...ServicesTilesFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_STATISTICS {
                  ...StatsFragment
              }
        }
    }

    
    strapiSiteConfig {
        twitter_link
        instagram_link
        linkedin_link
        facebook_link
        add_contact_details {
          phone
        }
      }
  }
`